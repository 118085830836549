import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import favicon from './favicon.ico'

import Header from './header'
import Footer from './footer'
import 'normalize.css/normalize.css'
import 'tachyons/css/tachyons.css'
import 'prismjs/themes/prism.css'
import './layout.css'

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet link={[{ rel: 'shortcut icon', href: `${favicon}` }]} />
        <Header siteTitle={data.site.siteMetadata.title} />
        <div className="">{children}</div>
        <Footer />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `window.$crisp=[];window.CRISP_WEBSITE_ID="fc600f50-ee58-407f-903b-8184c5952765";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`,
          }}
        />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
